<template>
  <b-modal
      v-model="visibleModal"
      title="Zmiana statusu gabarytu"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <form>
      <div class="form-group">
        <h4 class="text-center">Typ gabarytu</h4>

        <div class="row d-flex justify-content-center">
          <div class="col-12 col-lg-10">
            <button v-for="(item, index) in packageTypes" :key="index" class="package-type" :class="{ 'active-package': form.oversizeType === item, 'font-size-24': item === 'NONE' }" type="button" @click="form.oversizeType = item">{{ oversizeTypeHelper.getOversizeTypeText(item) }}</button>
          </div>
        </div>

        <input hidden :class="{ 'is-invalid': $v.form.oversizeType.$error }" />
        <div v-if="!$v.form.oversizeType.required" class="invalid-feedback">{{ $t('message.required') }}</div>
      </div>

      <div class="text-center">
        <ecat-button :callback="runCallback" variant="success" message="Zapisz" />
        <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t('message.cancel') }}</b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {oversizeTypeHelper} from "../../helpers/oversize-type-helper";

export default {
  name: "change-oversize-modal",

  data() {
    return {
      visibleModal: false,
      callback: null,
      packageTypes: [ "TYPE_A", "TYPE_B", "TYPE_C", "NONE"],

      form: {
        oversizeType: "NONE"
      }
    }
  },

  validations: {
    form: {
      oversizeType: { required }
    }
  },

  computed: {
    oversizeTypeHelper() {
      return oversizeTypeHelper
    }
  },

  methods: {
    openModal(callback) {
      this.callback = callback
      this.visibleModal = true
    },

    hideModal() {
      this.form.oversizeType = ""
      this.visibleModal = false
    },

    getOversizeType() {
      return this.form.oversizeType
    },

    async runCallback() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      try {
        await this.callback(this.form.oversizeType)
        this.hideModal()
      } catch (error) {
        // ignored
      }
    }
  }
}
</script>